<template>
  <div>
    <p style="text-align: left;color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);font-size: 16px;"><strong>Politique de Confidentialit&eacute;</strong></p>
<p style="text-align: left;color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);font-size: 16px;">Derni&egrave;re mise &agrave; jour : 01/08/2023</p>
<p style="text-align: left;color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);font-size: 16px;">Nous accordons une grande importance &agrave; la confidentialit&eacute; de vos donn&eacute;es. Cette politique de confidentialit&eacute; d&eacute;crit comment nous collectons, utilisons et prot&eacute;geons vos informations lorsque vous utilisez notre site web [Nom de votre site web]. En utilisant ce site, vous consentez aux pratiques d&eacute;crites dans cette politique.</p>
<p style="text-align: left;color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);font-size: 16px;"><strong>Collecte de Donn&eacute;es</strong></p>
<p style="text-align: left;color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);font-size: 16px;">Nous pouvons collecter des informations personnelles lorsque vous utilisez notre site. Les informations que nous pouvons collecter incluent [liste des types d&rsquo;informations collect&eacute;es, par exemple : nom, adresse e-mail, informations d&eacute;mographiques, etc.]. Nous collectons ces informations de diff&eacute;rentes mani&egrave;res, y compris lorsque vous les soumettez volontairement en remplissant des formulaires sur notre site.</p>
<p style="text-align: left;color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);font-size: 16px;"><strong>Utilisation des Donn&eacute;es</strong></p>
<p style="text-align: left;color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);font-size: 16px;">Nous utilisons les informations collect&eacute;es pour personnaliser l&rsquo;exp&eacute;rience de l&rsquo;utilisateur. Nous pouvons &eacute;galement partager des informations agr&eacute;g&eacute;es et anonymes avec des tiers &agrave; des fins statistiques et analytiques.</p>
<p style="text-align: left;color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);font-size: 16px;"><strong>Protection des Donn&eacute;es</strong></p>
<p style="text-align: left;color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);font-size: 16px;">Nous prenons des mesures de s&eacute;curit&eacute; appropri&eacute;es pour prot&eacute;ger vos informations contre tout acc&egrave;s non autoris&eacute;, divulgation, alt&eacute;ration ou destruction. Cependant, veuillez noter que la transmission de donn&eacute;es sur Internet n&rsquo;est pas totalement s&eacute;curis&eacute;e et qu&rsquo;il peut y avoir des risques associ&eacute;s &agrave; cette transmission.</p>
<p style="text-align: left;color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);font-size: 16px;"><strong>Cookies et Technologies Similaires</strong></p>
<p style="text-align: left;color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);font-size: 16px;">Nous utilisons des cookies et des technologies similaires pour personnaliser le contenu, analyser les tendances. Vous pouvez g&eacute;rer vos pr&eacute;f&eacute;rences en mati&egrave;re de cookies via les param&egrave;tres de votre navigateur.</p>
<p style="text-align: left;color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);font-size: 16px;"><strong>Liens Externes</strong></p>
<p style="text-align: left;color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);font-size: 16px;">Notre site peut contenir des liens vers des sites externes. Nous ne sommes pas responsables des pratiques de confidentialit&eacute; ou du contenu de ces sites externes. Nous vous encourageons &agrave; consulter les politiques de confidentialit&eacute; de ces sites avant de fournir des informations personnelles.</p>
<p style="text-align: left;color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);font-size: 16px;"><strong>Vos Droits</strong></p>
<p style="text-align: left;color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);font-size: 16px;">Vous avez le droit d&rsquo;acc&eacute;der &agrave; vos informations personnelles et de demander leur rectification, leur suppression ou leur restriction. Pour exercer ces droits, veuillez nous contacter &agrave; info@rcdi.bf.</p>
<p style="text-align: left;color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);font-size: 16px;"><strong>Modifications de la Politique de Confidentialit&eacute;</strong></p>
<p style="text-align: left;color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);font-size: 16px;">Nous nous r&eacute;servons le droit de modifier cette politique de confidentialit&eacute; &agrave; tout moment. Toute modification sera publi&eacute;e sur cette page et prendra effet imm&eacute;diatement apr&egrave;s la publication.</p>
<p style="text-align: left;color: rgb(51, 51, 51);background-color: rgb(255, 255, 255);font-size: 16px;">Si vous avez des questions concernant cette politique de confidentialit&eacute;, veuillez nous contacter &agrave; info@rcdi.bf.</p>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
}
</script>
